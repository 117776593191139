import axios from 'axios'

import { store } from 'redux/store'
import { setLoading } from 'redux/info-slice'
import { errorNotify, notify } from 'components/common/utils/helpers'
import { clear } from 'redux/auth/auth-slice'
import { clearNotifications } from 'redux/notificationsSlice'
import Cookies from 'js-cookie'
import { logout } from 'redux/auth/auth-operations'


let host = `https://${window.location.hostname}/`


if (window.location.host === 'localhost:3000') {
  host = 'https://dev.paxle-spot.com/'
}



export const instance = axios.create({
  baseURL: host,
  headers: {
    // 'Content-Type': 'application/json',
    'Content-Type': 'multipart/form-data',
    'Accept': 'application/json',
  },
})


// Список роутов по которым обрабатываеться global error
const ACCEPT_ROUTES = ['offer/v2']

const checkRoutes = (currentUrl, accepsList) => {
  let isHasRoute = false

  for (let key of accepsList) {
    if (currentUrl.includes(key)) isHasRoute = true
  }

  return isHasRoute
}

let isRefreshing = null

let countResquests = 0

instance.interceptors.request.use(
  function (config) {
    countResquests = countResquests + 1
    if (countResquests === 1) store.dispatch(setLoading(true))
    return config
  },
  function (error) {
    return Promise.reject(error)
  }
)

instance.interceptors.response.use(
  function (response) {
    // Любой код состояния, находящийся в диапазоне 2xx, вызывает срабатывание этой функции
    if(response.data === 'Token expired' || response.data === 'Token Does Not Exist') {
      store.dispatch(logout())
    }

    if (response?.data?.status === 'error') {
      const url = response.config.url
      const isRouteForNotify = checkRoutes(url, ACCEPT_ROUTES)
      console.log('interceptors url', response.config.url)

      if (!isRouteForNotify) errorNotify(response.data)
    }

    countResquests = countResquests - 1
    if (countResquests === 0) store.dispatch(setLoading(false))
    return response
  },
  function (error) {
    countResquests = countResquests - 1
    if (countResquests === 0) store.dispatch(setLoading(false))
    
    
    if (!isRefreshing && (error.response?.status === 401 || error.response?.status === 403)) {
      store.dispatch(logout())

      Cookies.remove('Authorization')
      Cookies.remove('sessionid')
      Cookies.remove('sessionrole')
      Cookies.remove('sessionuuid')
      Cookies.remove('teamleadteams')
      
      store.dispatch(clear())
      store.dispatch(clearNotifications())
    }

   

    if (error.response?.status !== 401) notify(error.message)

    // Возвращаем промис, чтобы запрос повторился после обновления токена
    // Любые коды состояния, выходящие за пределы диапазона 2xx, вызывают срабатывание этой функции
    return isRefreshing ? isRefreshing : Promise.reject(error)
  }
)
