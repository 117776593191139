import { useEffect } from 'react'
import UserRoutes from './Routes/UserRoutes'

const App = () => {
  useEffect(() => {
    const commitHash = process.env.REACT_APP_COMMIT_HASH;

    if (commitHash) {
      document.body.setAttribute('data-commit-hash', commitHash);
    }
  }, [])

  return <UserRoutes />
}

export default App
