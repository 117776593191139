// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:root {
  --white: #FFF;
  --black: #000;
  --bright: #CED2D8;
  --blue: #0039E3;
  --pink: #FF0F6B;
  --red: #CB1A1A;
  --green: #AAEE00;
  --purple: #733DFF;
  --hover-tab: #9F79FF;
  --light-purple: #CBB7FF;
  --orange: #FDA700;
  --orange-1: #FF740F;
  --light-grey: #848484;
  --grey-text: #5A5A5A;
  --purple-text: #3A09B9;
  --lavender: #F0EAFF;
  --pale-gray: #F9F9F9;
  --silver-gray: #F0F0F0;
  --neutral-gray: #D9D9D9;
  --bright-yellow: #FFD600;
  --bright-blue: #00BCF2;
  --emerald-green: #00BC4B;
  --electric-purple: #B20FFF;
  --deep-purple: #2A0292;
  --deep-blue: #3A0AB9;
  --royal-violet: #4D17DA;
}
`, "",{"version":3,"sources":["webpack://./src/styles/colors.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,aAAa;EACb,iBAAiB;EACjB,eAAe;EACf,eAAe;EACf,cAAc;EACd,gBAAgB;EAChB,iBAAiB;EACjB,oBAAoB;EACpB,uBAAuB;EACvB,iBAAiB;EACjB,mBAAmB;EACnB,qBAAqB;EACrB,oBAAoB;EACpB,sBAAsB;EACtB,mBAAmB;EACnB,oBAAoB;EACpB,sBAAsB;EACtB,uBAAuB;EACvB,wBAAwB;EACxB,sBAAsB;EACtB,wBAAwB;EACxB,0BAA0B;EAC1B,sBAAsB;EACtB,oBAAoB;EACpB,uBAAuB;AACzB","sourcesContent":[":root {\n  --white: #FFF;\n  --black: #000;\n  --bright: #CED2D8;\n  --blue: #0039E3;\n  --pink: #FF0F6B;\n  --red: #CB1A1A;\n  --green: #AAEE00;\n  --purple: #733DFF;\n  --hover-tab: #9F79FF;\n  --light-purple: #CBB7FF;\n  --orange: #FDA700;\n  --orange-1: #FF740F;\n  --light-grey: #848484;\n  --grey-text: #5A5A5A;\n  --purple-text: #3A09B9;\n  --lavender: #F0EAFF;\n  --pale-gray: #F9F9F9;\n  --silver-gray: #F0F0F0;\n  --neutral-gray: #D9D9D9;\n  --bright-yellow: #FFD600;\n  --bright-blue: #00BCF2;\n  --emerald-green: #00BC4B;\n  --electric-purple: #B20FFF;\n  --deep-purple: #2A0292;\n  --deep-blue: #3A0AB9;\n  --royal-violet: #4D17DA;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
